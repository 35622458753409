@import 'components/Footer';
$breakpoints: (
    phone: 640px,
    tablet: 768px,
    desktop: 1024px,
) !default;

// @include media(">phone", "<tablet") {
// }

// @include media(">tablet", "<950px") {
// }

.App {
    text-align: center;
}

.App-logo {
    height: 20vmin;
    pointer-events: none;
}

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

.App-header {
    background-color: #171717;
    background-image: url(./assets/images/cartographer.png);
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.navbar-logo {
    height: 5vmin;
}

a,
a:visited,
a:hover,
a:active {
    text-decoration: none;
    color: white;
    cursor: pointer;
}
.navbar-buttons {
    padding: 0.5rem;
}
.navbar-buttons :hover {
    border-bottom: solid 3px #fcee3d;
}
//.navbar-buttons a {
//    color: black;
//}
.App-link {
    color: #61dafb;
}
.footer {
    padding: 4em 3em 2em;
    text-align: center;
    background-color: #3a3a3a;
}

.copyright {
    color: #ffffff80;
    font-size: 0.8em;
    letter-spacing: 0.225em;
    list-style: none;
    padding: 0;
    text-transform: uppercase;
}
.copyright li:first-child {
    border-left: 0;
    margin-left: 0;
    padding-left: 0;
}
.copyright li {
    border-left: 1px solid #ffffff80;
    display: inline-block;
    line-height: 1em;
    margin-left: 1em;
    padding-left: 1em;
}
.flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    perspective: 1000px;
    margin: 1rem;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    background-color: #bbb;
    color: black;
}

.flip-card-back {
    background-color: #fcee3d;
    color: white;
    transform: rotateY(180deg);
}

.presentation-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: space-around;
    background-color: #ececec;
    padding: 1rem;
    @media (min-width: 0px) and (max-width: 640px) {
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: nowrap;
    }
}
.presentation-image-card {
    width: 40vw;
    height: 80vh;
    padding: 1rem;
    position: relative;
}
.presentation-back-image {
    height: 80%;
    width: 80%;
    background-color: #121212;
    position: absolute;
    @media (min-width: 0px) and (max-width: 640px) {
        width: 80vw;
    }
}
.presentation-image {
    height: 80%;
    width: 80%;
    position: absolute;
    top: 17%;
    left: 20%;
    @media (min-width: 0px) and (max-width: 640px) {
        width: 80vw;
    }
}

.presentation-text {
    width: 40vw;
    height: 80vh;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (min-width: 0px) and (max-width: 640px) {
        width: 80vw;
    }
}

// body {
//     background-color: #444442;
//     padding-top: 85px;
// }

// h1 {
//     font-family: 'Poppins', sans-serif, 'arial';
//     font-weight: 600;
//     font-size: 72px;
//     color: white;
//     text-align: center;
// }

// h4 {
//     font-family: 'Roboto', sans-serif, 'arial';
//     font-weight: 400;
//     font-size: 20px;
//     color: #9b9b9b;
//     line-height: 1.5;
// }

/* ///// inputs /////*/

input:focus ~ label,
textarea:focus ~ label,
input:valid ~ label,
textarea:valid ~ label {
    font-size: 0.75em;
    color: #999;
    top: -5px;
    -webkit-transition: all 0.225s ease;
    transition: all 0.225s ease;
}

.styled-input {
    float: left;
    width: 293px;
    margin: 1rem 0;
    position: relative;
    border-radius: 4px;
}

@media only screen and (max-width: 768px) {
    .styled-input {
        width: 100%;
    }
}

.styled-input label {
    color: #999;
    padding: 1.3rem 30px 1rem 30px;
    position: absolute;
    top: 10px;
    left: 0;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    pointer-events: none;
}

.styled-input.wide {
    width: 650px;
    max-width: 100%;
}

input,
textarea {
    padding: 30px;
    border: 0;
    width: 100%;
    font-size: 1rem;
    background-color: #5d5d5d;
    color: white;
    border-radius: 4px;
}

input:focus,
textarea:focus {
    outline: 0;
}

input:focus ~ span,
textarea:focus ~ span {
    width: 100%;
    -webkit-transition: all 0.075s ease;
    transition: all 0.075s ease;
}

textarea {
    width: 100%;
    min-height: 15em;
}

.input-container {
    width: 650px;
    max-width: 100%;
    margin: 20px auto 25px auto;
}

.submit-btn {
    float: right;
    padding: 7px 35px;
    border-radius: 60px;
    display: inline-block;
    background-color: #4b8cfb;
    color: white;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06),
        0 2px 10px 0 rgba(0, 0, 0, 0.07);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
}

.submit-btn:hover {
    transform: translateY(1px);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.09);
}

@media (max-width: 768px) {
    .submit-btn {
        width: 100%;
        float: none;
        text-align: center;
    }
}

input[type='checkbox'] + label {
    color: #ccc;
    font-style: italic;
}

input[type='checkbox']:checked + label {
    color: #f00;
    font-style: normal;
}
