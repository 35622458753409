.footer-icons {
    width: 2rem;
    height: 2rem;
    margin: 0 1rem;
}

.icons {
    cursor: default;
    list-style: none;
    padding-left: 0;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 0 6rem;
}
